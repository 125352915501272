<template>
  <div class="step-form">
    <div class="mb-1 text-center">
      <h2 class="title ff-arial fs-24 fw-600">Product Information</h2>
    </div>
    <form>
      <div class="mb-3">
        <slot>
          <p>Start describing the goods and services related to your mark. Trademark Engine compares the description you provide and automatically provides descriptions from the USPTO ID Manual for your consideration.</p>
        </slot>
      </div>

      <trademark-class-item
        v-for="id in countRange"
        :key="id"
        :id="id"
        @add="addItem"
        @delete="deleteItem"
      />
    </form>
  </div>
</template>


<script>
import range from 'lodash.range'
import store from '@/store'
import TrademarkClassItem from './TrademarkClassItem'

export default {
  props: {
    showPrimaryActivity: {
      type: Boolean,
      default: true
    }
  },
  components: {
    TrademarkClassItem
  },
  methods: {
    deleteItem(id) {
      store.commit('forms/DELETE_ITEM_IN_FORM_ARRAY', {type: 'FormTrademarkClass', at: id})
    },
    addItem() {
      store.commit(
        'forms/ADD_NEW_ITEM_IN_FORM_ARRAY',
        {
          type: 'FormTrademarkClass'
        }
      )
    }
  },
  computed: {
    countRange() {
      return range(store.state.forms.FormTrademarkClass.length)
    }
  }
}
</script>
