<template>
  <div>
    <div class="mb-1 border-bottom pb-0" v-if="showTitle">
      <strong>{{type}} #{{id+1}}</strong>
    </div>
    <div class="mb-1">
        <label :for="'name-'+id" class="form-label">Full Name *</label>
        <input 
          type="text" 
          :id="'name-'+id"
          name="name" 
          class="form-control" 
          :value="$store.state.forms.FormOrgMember[id].memberName"
          @input="handleNameChange"
        >
    </div>

    <div v-if="!showNameOnly">
      <div class="mb-1">
          <label :for="'address-'+id" class="form-label">Address *</label>
          <input
            type="text" 
            :id="'address-'+id"
            name="address"
            class="form-control"
            :value="$store.state.forms.FormOrgMember[id].memberAddress"
            @input="handleAddressChange"
            required
          >
      </div>
      
      <div class="mb-1">
        <label :for="'address1-'+id" class="form-label">Apt, suite, unit, etc</label>
        <input 
          type="text" 
          :id="'address1-'+id"
          name="address1" 
          class="form-control" 
          :value="$store.state.forms.FormOrgMember[id].memberAddress1"
          @input="handleAddress1Change"
        >
      </div>

      <div class="mb-1">
          <label :for="'city-'+id" class="form-label">City *</label>
          <input 
            type="text" 
            :id="'city-'+id" 
            name="city" 
            class="form-control" 
            :value="$store.state.forms.FormOrgMember[id].memberCity"
            @input="handleCityChange"
          >
      </div>

      <select-state 
        :name="'state-'+id"
        :value="$store.state.forms.FormOrgMember[id].memberState"
        @input="handleStateChange"
        rules="required"
      />

      <div class="mb-1">
          <label :for="'zip-'+id" class="form-label">ZIP *</label>
          <input 
            type="number" 
            :id="'zip-'+id" 
            name="zip" 
            class="form-control" 
            :value="$store.state.forms.FormOrgMember[id].memberZip"
            @input="handleZipChange"
          >
      </div>

      <div class="mb-1">
          <label :for="'phone-'+id" class="form-label">Phone *</label>
          <input 
            type="text" 
            :id="'phone-'+id" 
            name="phone" 
            class="form-control" 
            :value="$store.state.forms.FormOrgMember[id].memberPhone"
            @input="handlePhoneChange"
            ref="phone"
          >
      </div>

      <div class="mb-1">
          <label :for="'email-'+id" class="form-label">Email *</label>
          <input 
            type="email" 
            :id="'email-'+id" 
            name="email" 
            class="form-control" 
            :value="$store.state.forms.FormOrgMember[id].memberEmail"
            @input="handleEmailChange"
          >
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'

export default {
  props: {
    id: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: 'Owner'
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    showNameOnly: {
      type: Boolean,
      default: false
    },
    addRemoveButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      phoneMask: null
    }
  },
  methods: {
    handleDelete() {
      if (confirm(`Are you sure to delete this ${this.type.toLowerCase()}?`)) {
        this.$emit('delete', this.id)
      }
    },
    handleAdd() {
      this.$emit('add')
    },
    handleNameChange(e) {
      store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormOrgMember[${this.id}].memberName`, value: e.target.value})
    },
    handleAddressChange(e) {
      store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormOrgMember[${this.id}].memberAddress`, value: e.target.value})
    },
    handleAddress1Change(e) {
      store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormOrgMember[${this.id}].memberAddress1`, value: e.target.value})
    },
    handleCityChange(e) {
      store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormOrgMember[${this.id}].memberCity`, value: e.target.value})
    },
    handleStateChange(e) {
      store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormOrgMember[${this.id}].memberState`, value: e})
    },
    handleZipChange(e) {
      store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormOrgMember[${this.id}].memberZip`, value: e.target.value})
    },
    handlePhoneChange(e) {
      store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormOrgMember[${this.id}].memberPhone`, value: e.target.value})
    },
    handleEmailChange(e) {
      store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormOrgMember[${this.id}].memberEmail`, value: e.target.value})
    },
  },
}
</script>
