<template>
  <div>
    <select-radio :name="'product-type-'+id" opt-class="mb-0"
      :items="[{name: 'Goods', value: 'goods'}, {name: 'Services', value: 'services'}]"
      :value="$store.state.forms.FormTrademarkClass[id].productType"
      @input="handleProductTypeChange"
    >
      Do you sell a goods or service? *
    </select-radio>

    <select-trademark-classification
      :type="$store.state.forms.FormTrademarkClass[id].productType"
      :value="$store.state.forms.FormTrademarkClass[id].productCategory"
      @input="handleProductCategoryChange"
      rules="required"
    >
      Which of these categories best describes the industry or way in which you will use your mark? *
    </select-trademark-classification>

    <div class="mb-4">
      <label :for="'product-description-'+id" class="form-label">Provide a few more details on the goods and services you are selling with your trademark. *</label>
      <textarea 
        class="form-control"
        :id="'product-description-'+id"
        rows="5"
        :value="$store.state.forms.FormTrademarkClass[id].productDescription"
        @input="handleProductDescriptionChange"
      >
      </textarea>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import SelectTrademarkClassification from './common/SelectTrademarkClassification'

export default {
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  components: {
    SelectTrademarkClassification
  },
  methods: {
    handleDelete() {
      if (confirm(`Are you sure to delete this item?`)) {
        this.$emit('delete', this.id)
      }
    },
    handleAdd() {
      this.$emit('add')
    },
    handleProductTypeChange(e) {
      store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormTrademarkClass[${this.id}].productType`, value: e})
      // reset category
      store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormTrademarkClass[${this.id}].productCategory`, value: ''})
    },
    handleProductCategoryChange(e) {
      store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormTrademarkClass[${this.id}].productCategory`, value: e})
    },
    handleProductDescriptionChange(e) {
      store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormTrademarkClass[${this.id}].productDescription`, value: e.target.value})
    },
  }
}
</script>
