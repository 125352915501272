<template>
  <div class="step-form">
    <div class="mb-1 text-center">
      <h2 class="title ff-arial fs-24 fw-600">Trademark Information</h2>
    </div>
    <form>
      <select-radio name="mark-type" opt-class="mb-0"
        :items="[{name: 'Name', value: 'name'}, {name: 'Slogan', value: 'slogan'}, {name: 'Logo', value: 'logo'}]"
        v-model="markType"
      >
        Select what you are trying to protect
      </select-radio>
      
      <!-- name details -->
      <base-input
        name="mark-type-name"
        label="Enter the name you wish to protect *"
        v-model="name"
        v-if="markType==='name'"
        rules="required"
      />

      <!-- slogan details -->
      <base-input
        name="mark-type-slogan"
        label="Enter the slogan you wish to protect *"
        v-model="slogan"
        v-if="markType==='slogan'"
        rules="required"
      />

      <!-- logo details -->
      <base-input
        name="mark-type-logo"
        v-model="logoDescription"
        v-if="markType==='logo'"
        rules="required"
      >
        <template v-slot:label>
          <p class="text-example text-caution mb-1">
            Please note, you will be asked to upload your logo after you create your account.
          </p>

          <label for="mark-type-logo" class="form-label">
            Please provide a complete and accurate description of your logo, including any words that may appear in the logo*
          </label>
          <p class="mb-0 text-example">
            Example: ‘a red cat wrapped around a blue outline of a globe’. Leave out the colors if you want to protect your logo in all colors
          </p>
        </template>
      </base-input>

      <select-yes-no-radio name="all-colors-logo" v-model="logoAllColor" v-if="markType==='logo'">
        Do you want to be able to protect your logo in a variety of colors?
      </select-yes-no-radio>

      <base-input
        name="logo-colors"
        label="Please provide a list of the colors that appear in your logo *"
        v-model="logoColors"
        v-if="markType==='logo' && logoAllColor===false"
        rules="required"
      />

      <base-input
        name="words-in-logo"
        label="Enter any word(s), letter(s), and/or number(s) that appear in your logo *"
        v-model="logoWords"
        v-if="markType==='logo'"
        rules="required"
      />
      <!-- end of logo details -->

      <select-yes-no-radio name="include-live-person-name" opt-class="mb-0" v-model="includeLivePersonName">
        Does your mark include a living person’s name—even if a stage name or pseudonym or could it be perceived to include a person’s name?
      </select-yes-no-radio>

      <!-- case of including person's name -->
      <select-yes-no-radio name="include-your-name" opt-class="mb-0" v-model="includeYourName" v-show="includeLivePersonName">
        Does your mark include a name (including a pseudonym, nickname or stage name) that is yours?
      </select-yes-no-radio>
      <base-input
        name="your-legal-name-mark-reference"
        label="Please type the legal name to whom the mark references. *"
        v-model="yourLegalName"
        v-if="includeLivePersonName && includeYourName"
        rules="required"
      />

      <select-yes-no-radio name="include-others-name" opt-class="mb-0" v-model="includeOthersName" v-show="includeLivePersonName && !includeYourName">
        Does your mark include the name (including a pseudonym, nickname or stage name) of a living person other than yourself?
      </select-yes-no-radio>
      <p class="text-example text-caution mb-0" v-show="includeLivePersonName && includeOthersName">
        Please note that you will need the living person’s written consent to the use and registration of the name before we can file it.
      </p>
      <base-input
        name="your-legal-name-mark-reference"
        label="What is the name of the living person in your mark? *"
        v-model="othersName"
        v-if="includeLivePersonName && includeOthersName"
        rules="required"
      />
      <!-- case of including person's name -->
    </form>
  </div>
</template>

<script>
import store from '@/store'

export default {
  props: {
    title: {
      type: String,
      default: 'Contact Information'
    },
    showTitle: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    markType: {
      get() {
        return store.state.forms.FormTrademarkInfo.markType
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTrademarkInfo.markType', value})
        if (value === 'logo') {
          store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTrademarkInfo.searchReportType', value: 'no-logo-search'})
        } else {
          store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTrademarkInfo.searchReportType', value: 'trademark-basic-federal-direct-hit-search'})
        }
      }
    },
    name: {
      get() {
        return store.state.forms.FormTrademarkInfo.name
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTrademarkInfo.name', value})
      }
    },
    slogan: {
      get() {
        return store.state.forms.FormTrademarkInfo.slogan
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTrademarkInfo.slogan', value})
      }
    },
    logoDescription: {
      get() {
        return store.state.forms.FormTrademarkInfo.logoDescription
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTrademarkInfo.logoDescription', value})
      }
    },
    logoAllColor: {
      get() {
        return store.state.forms.FormTrademarkInfo.logoAllColor
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTrademarkInfo.logoAllColor', value})
      }
    },
    logoColors: {
      get() {
        return store.state.forms.FormTrademarkInfo.logoColors
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTrademarkInfo.logoColors', value})
      }
    },
    logoWords: {
      get() {
        return store.state.forms.FormTrademarkInfo.logoWords
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTrademarkInfo.logoWords', value})
      }
    },
    includeLivePersonName: {
      get() {
        return store.state.forms.FormTrademarkInfo.includeLivePersonName
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTrademarkInfo.includeLivePersonName', value})
      }
    },
    includeYourName: {
      get() {
        return store.state.forms.FormTrademarkInfo.includeYourName
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTrademarkInfo.includeYourName', value})
      }
    },
    yourLegalName: {
      get() {
        return store.state.forms.FormTrademarkInfo.yourLegalName
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTrademarkInfo.yourLegalName', value})
      }
    },
    includeOthersName: {
      get() {
        return store.state.forms.FormTrademarkInfo.includeOthersName
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTrademarkInfo.includeOthersName', value})
      }
    },
    othersName: {
      get() {
        return store.state.forms.FormTrademarkInfo.othersName
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTrademarkInfo.othersName', value})
      }
    },
  }
}
</script>
