<template>
  <div :class="optClass">
    <label :for="generatedId" class="form-label"><slot>Product Category *</slot></label>
    <div class="select">
      <select class="form-control" :id="generatedId" @change="change" :value="value" v-if="type==='goods'">
        <option value="">Select an option</option>
        <option value="1">001 - Chemicals</option>
        <option value="2">002 - Paints</option>
        <option value="3">003 - Cosmetics and Cleaning Products</option>
        <option value="4">004 - Lubricants and Fuels</option>
        <option value="5">005 - Pharmaceuticals</option>
        <option value="6">006 - Metal Products</option>
        <option value="7">007 - Machinery</option>
        <option value="8">008 - Hand Tools</option>
        <option value="9">009 - Computer and Software and Electrical and Scientific Products</option>
        <option value="10">010 - Medical Instruments</option>
        <option value="11">011 - Environmental Control Apparatus</option>
        <option value="12">012 - Vehicles</option>
        <option value="13">013 - Firearms and Explosives and Fireworks</option>
        <option value="14">014 - Jewelry and Precious Metals and Watches</option>
        <option value="15">015 - Musical Instruments</option>
        <option value="16">016 - Paper Goods and Printed Material</option>
        <option value="17">017 - Rubber Products</option>
        <option value="18">018 - Leather Products (not clothing)</option>
        <option value="19">019 - Building Materials (non-metallic)</option>
        <option value="20">020 - Furniture</option>
        <option value="21">021 - Houseware and Glass Products</option>
        <option value="22">022 - Ropes and Tents and Sails and Bags and Fiber products</option>
        <option value="23">023 - Yarns and Threads</option>
        <option value="24">024 - Fabrics and Textiles</option>
        <option value="25">025 - Clothing and Footwear</option>
        <option value="26">026 - Lace and Ribbons and Embroidery and Fancy Goods</option>
        <option value="27">027 - Floor Coverings</option>
        <option value="28">028 - Toys and Sporting Goods</option>
        <option value="29">029 - Meat and Fish and Poultry and Processed Food Products</option>
        <option value="30">030 - Staple Foods</option>
        <option value="31">031 - Natural Agricultural Products</option>
        <option value="32">032 - Light Beverages</option>
        <option value="33">033 - Wines and Spirits</option>
        <option value="34">034 - Smokers Articles</option>
      </select>

      <select class="form-control" :id="generatedId" @change="change" :value="value" v-if="type==='services'">
        <option value="">Select an option</option>
        <option value="35">035 - Advertising and Business Management and Office Functions</option>
        <option value="36">036 - Insurance and Financial and Real Estate Affairs</option>
        <option value="37">037 - Construction and Repair and Installation Services</option>
        <option value="38">038 - Communication Services</option>
        <option value="39">039 - Transport and Storage and Travel</option>
        <option value="40">040 - Treatment of Materials</option>
        <option value="41">041 - Education and Entertainment and Sporting and Cultural Activities</option>
        <option value="42">042 - Computer and Software services and Scientific Services</option>
        <option value="43">043 - Restaurant and Hotel Services</option>
        <option value="44">044 - Medical and Veterinarian Services</option>
        <option value="45">045 - Legal and Personal and Security services</option>
      </select>
    </div>
  </div>
</template>

<script>
import BaseSelect from './BaseSelect'

export default {
  mixins: [BaseSelect],
  props: {
    name: {
      type: String,
      default: 'primaryActivity'
    },
    optClass: {
      type: String,
      default: 'mb-3'
    },
    type: {
      type: String, /* goods | services */
      default: 'goods'
    },
    value: String,
    rules: {
      type: String,
      default: ''
    }
  },
}
</script>