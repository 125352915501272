<template>
  <div class="step-form">
    <div class="mb-1 text-center">
      <h2 class="title ff-arial fs-24 fw-600">Additional Information</h2>
    </div>
    <form>
      <select-yes-no-radio name="include-non-latin" opt-class="mb-0" v-model="includeNonLatin">
        Does your mark include any words other than English or non-Latin characters?
      </select-yes-no-radio>

      <base-input
        name="mark-type-slogan"
        label="Please provide the English translation or transliteration of any non-Latin characters or non-English words: *"
        v-model="translateContent"
        v-if="includeNonLatin"
        rules="required"
      />

      <select-yes-no-radio name="use-trademark-already" opt-class="mb-0" v-model="usingThisAlready">
        Are you currently using this trademark in your business?
      </select-yes-no-radio>

      <p class="mb-1" v-if="usingThisAlready">First use anywhere:</p>
      <div class="d-flex mb-3" v-if="usingThisAlready">  
        <select-month v-model="firstUseMonth" name="first-use-month" opt-class="triple-picker"/>
        <select-date v-model="firstUseDate" name="first-use-date" opt-class="triple-picker"/>
        <select-year v-model="firstUseYear" name="first-use-year" opt-class="triple-picker"/>
      </div>

      <p class="mb-1" v-if="usingThisAlready">First use in commerce:</p>
      <div class="d-flex mb-3" v-if="usingThisAlready">  
        <select-month v-model="firstUseEcommerceMonth" name="first-use-ecommerce-month" opt-class="triple-picker"/>
        <select-date v-model="firstUseEcommerceDate" name="first-use-ecommerce-date" opt-class="triple-picker"/>
        <select-year v-model="firstUseEcommerceYear" name="first-use-ecommerce-year" opt-class="triple-picker"/>
      </div>
    </form>
  </div>
</template>


<script>
import store from '@/store'

export default {
  props: {
    title: {
      type: String,
      default: 'Contact Information'
    },
    showTitle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      contactEmail2: '',
      contactTel2: '',
    }
  },
  computed: {
    includeNonLatin: {
      get() {
        return store.state.forms.FormTrademarkInfo.includeNonLatin
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTrademarkInfo.includeNonLatin', value})
      }
    },
    translateContent: {
      get() {
        return store.state.forms.FormTrademarkInfo.translateContent
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTrademarkInfo.translateContent', value})
      }
    },
    usingThisAlready: {
      get() {
        return store.state.forms.FormTrademarkInfo.usingThisAlready
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTrademarkInfo.usingThisAlready', value})
      }
    },
    // first use time
    firstUseYear: {
      get() {
        return `${new Date(store.state.forms.FormTrademarkInfo.firstUseDate).getFullYear()}`
      },
      set(value) {
        const fullDate = new Date(store.state.forms.FormTrademarkInfo.firstUseDate)
        fullDate.setFullYear(parseInt(value))
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTrademarkInfo.firstUseDate', value: fullDate})
      }
    },
    firstUseMonth: {
      get() {
        return `${new Date(store.state.forms.FormTrademarkInfo.firstUseDate).getMonth()}`
      },
      set(value) {
        const fullDate = new Date(store.state.forms.FormTrademarkInfo.firstUseDate)
        fullDate.setMonth(parseInt(value))
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTrademarkInfo.firstUseDate', value: fullDate})
      }
    },
    firstUseDate: {
      get() {
        return `${new Date(store.state.forms.FormTrademarkInfo.firstUseDate).getDate()}`
      },
      set(value) {
        const fullDate = new Date(store.state.forms.FormTrademarkInfo.firstUseDate)
        fullDate.setDate(parseInt(value))
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTrademarkInfo.firstUseDate', value: fullDate})
      }
    },
    // first use time in eCommerce
    firstUseEcommerceYear: {
      get() {
        return `${new Date(store.state.forms.FormTrademarkInfo.firstUseInEcommerceDate).getFullYear()}`
      },
      set(value) {
        const fullDate = new Date(store.state.forms.FormTrademarkInfo.firstUseInEcommerceDate)
        fullDate.setFullYear(parseInt(value))
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTrademarkInfo.firstUseInEcommerceDate', value: fullDate})
      }
    },
    firstUseEcommerceMonth: {
      get() {
        return `${new Date(store.state.forms.FormTrademarkInfo.firstUseInEcommerceDate).getMonth()}`
      },
      set(value) {
        const fullDate = new Date(store.state.forms.FormTrademarkInfo.firstUseInEcommerceDate)
        fullDate.setMonth(parseInt(value))
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTrademarkInfo.firstUseInEcommerceDate', value: fullDate})
      }
    },
    firstUseEcommerceDate: {
      get() {
        return `${new Date(store.state.forms.FormTrademarkInfo.firstUseInEcommerceDate).getDate()}`
      },
      set(value) {
        const fullDate = new Date(store.state.forms.FormTrademarkInfo.firstUseInEcommerceDate)
        fullDate.setDate(parseInt(value))
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTrademarkInfo.firstUseInEcommerceDate', value: fullDate})
      }
    },
  }
}
</script>
