<template>
  <div class="step-form">
    <div class="mb-1 text-center">
      <h2 class="title ff-arial fs-24 fw-600">Owners Information</h2>
    </div>
    <form>
      <div class="mb-0">
        <strong>Will the trademark be owned by an individual or an entity such as a corporation or LLC?</strong>
        <p class="mb-0">Identify the owner of the trademark. This is the person or organization who will be the owner of record. If you choose individuals, you can enter as many names as you want who own the mark. With an organization, you will have to identify someone to be the person of contact for the organization.</p>
      </div>
      <select-radio name="owned-by-individual" opt-class="mb-0"
        :items="[{name: 'Individual', value: true}, {name: 'Organization', value: false}]"
        v-model="ownedByIndividual"
      />

      <!-- individual owners -->
      <div v-if="ownedByIndividual">
        <member-info-input-2
          v-for="id in countRange"
          :key="id"
          :id="id"
          :show-name-only="id>0"
          @add="addOwner"
          @delete="deleteOwner"
        />
      </div>

      <!-- organization owner -->
      <div v-if="!ownedByIndividual">
        <!-- organization name -->
        <base-input
          name="organization-name"
          label="Organization Name *"
          v-model="organizationName"
          rules="required"
        />

        <select-entity-type v-model="organizationType" rules="required">
          Organization Type *
        </select-entity-type>

        <select-state name="state-of-formation" v-model="orgStateOfFormation" rules="required">
          State of Formation *
        </select-state>

        <base-input
          name="org-representative-position"
          label="Position *"
          v-model="orgRepresentativePosition"
          placeholder="example: CEO"
          rules="required"
        />

        <member-info-input-2
          :id="0"
          :show-title="false"
          :add-remove-button="false"
        />
      </div>
    </form>
  </div>
</template>

<script>
import range from 'lodash.range'
import store from '@/store'
import MemberInfoInput2 from './common/MemberInfoInput2'

export default {
  props: {},
  components: {
    MemberInfoInput2
  },
  methods: {
    addOwner() {
      store.commit(
        'ADD_NEW_ITEM_IN_FORM_ARRAY',
        {
          type: 'FormOrgMember'
        }
      )
    },
    deleteOwner(id) {
      if (id === 0) return alert('Sorry, but the primary owner can\'t be removed!')
      store.commit('DELETE_ITEM_IN_FORM_ARRAY', {type: 'FormOrgMember', at: id})
    },
  },
  computed: {
    countRange() {
      return range(store.state.forms.FormOrgMember.length)
    },
    ownedByIndividual: {
      get() {
        return store.state.forms.FormTrademarkInfo.ownedByIndividual
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTrademarkInfo.ownedByIndividual', value})
      }
    },
    organizationName: {
      get() {
        return store.state.forms.FormTrademarkInfo.organizationName
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTrademarkInfo.organizationName', value})
      }
    },
    organizationType: {
      get() {
        return store.state.forms.FormTrademarkInfo.organizationType
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTrademarkInfo.organizationType', value})
      }
    },
    orgStateOfFormation: {
      get() {
        return store.state.forms.FormTrademarkInfo.orgStateOfFormation
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTrademarkInfo.orgStateOfFormation', value})
      }
    },
    orgRepresentativePosition: {
      get() {
        return store.state.forms.FormTrademarkInfo.orgRepresentativePosition
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTrademarkInfo.orgRepresentativePosition', value})
      }
    },
  }
}
</script>