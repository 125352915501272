<template>
  <form-tabs>
    <form-tab tab-title="Contact Information">
      <form-contact-info />
    </form-tab>
    
    <form-tab tab-title="Trademark Information">
      <form-trademark-info />
    </form-tab>

    <form-tab icon="" tab-title="Additional Information">
      <form-trademark-extra />
    </form-tab>

    <form-tab icon="" tab-title="Owners Information">
      <form-trademark-owners />
    </form-tab>

    <form-tab icon="" tab-title="Product Information">
      <form-trademark-class />
    </form-tab>

    <form-tab icon="" tab-title="Search Report">
      <form-trademark-search-report />
    </form-tab>
  </form-tabs>
</template>

<script>
import FormTrademarkClass from '../../components/forms/FormTrademarkClass.vue'
import FormTrademarkExtra from '../../components/forms/FormTrademarkExtra.vue'
import FormTrademarkInfo from '../../components/forms/FormTrademarkInfo.vue'
import FormTrademarkOwners from '../../components/forms/FormTrademarkOwners.vue'
import FormTrademarkSearchReport from '../../components/forms/FormTrademarkSearchReport.vue'

export default {
  components: {
    FormTrademarkClass,
    FormTrademarkExtra,
    FormTrademarkInfo,
    FormTrademarkOwners,
    FormTrademarkSearchReport
  },
  setup() {
    
  },
}
</script>
